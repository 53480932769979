@import url('https://fonts.cdnfonts.com/css/nice-tango');

.flip-btn {
	font-family: 'Nice Tango', sans-serif;
	font-size: 4em;
	text-align: center;
	margin-top: 2em;
	position: relative;
    display: inline-block; 
    overflow: hidden; 
}

.flip-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
    animation: shine 2s infinite;
}
.balance-text {
    position: absolute;
    top: -10px;
    right: 10px;
    font-size: 2.3em;
    font-weight: bold;
    color: black; 
}
.leaderboard {
	margin-top: 9em;
	border: none;
	background-color: #fedf57;
	border-radius: 12px;
	font-family: 'Nice Tango', sans-serif;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5), 0 0 0 4px rgba(255, 255, 255, 0.2);
	animation: electric 1s infinite;
  }
  
  @keyframes electric {
	0% {
	  box-shadow: 0 0 0 2px rgba(216, 255, 22, 0.5), 0 0 0 4px rgba(255, 255, 255, 0.2);
	}
	50% {
	  box-shadow: 0 0 0 2px rgba(15, 133, 242, 0.2), 0 0 0 4px rgba(255, 255, 255, 0.5);
	}
	100% {
	  box-shadow: 0 0 0 2px rgba(11, 77, 243, 0.5), 0 0 0 4px rgba(255, 255, 255, 0.2);
	}
  }
  
  .leaderboard h3 {
	font-size: 2.5em;
	font-weight: 600;
	margin-bottom: 20px;
	color: #333;
  }
  
  .leaderboard-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
  }
  
  .leaderboard-item {
	font-size: 18px;
	padding: 12px 0;
	border-bottom: 1px solid #eee;
	transition: background-color 0.2s ease;
  }
  
  .leaderboard-item:hover {
	background-color: #f7f7f7;
  }
  
  .leaderboard-item:last-child {
	border-bottom: none;
  }

.connect-wallet-info {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
	border-radius: 11px;
	border: 3px solid rgba(0, 0, 0, 0.612);
    color: #000;
}
.btn-game {
	font-family: 'Nice Tango', sans-serif;
	font-size: 1em;
	border-radius: 11px;
	border: none;
	width: 7em;
	height: 3.5em;
	background-color: #fedf57;
	cursor: pointer;
	margin: 3em;
	transition: background-color 0.3s ease;
	transition: box-shadow .25s linear,-webkit-transform .35s ease-in-out;
    transition: transform .35s ease-in-out,box-shadow .25s linear;
    transition: transform .35s ease-in-out,box-shadow .25s linear,-webkit-transform .35s ease-in-out
}
.btn-game:hover {
	background-color: #fedf5788;
	-webkit-transform: scale(1.05);
    transform: scale(1.05)
}
.btn-game:hover {
	box-shadow: 0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08)
}
.btn-section {
	text-align: center;
	margin-top: 3em;
}
.img-coin {
	text-align: center;
	margin-top: 6em;
}
.game-txt {
	font-family: 'Nice Tango', sans-serif;
	font-size: 2.1em;
	text-align: center;
	margin-top: 2em;
	text-shadow: 0 0 0 2rem white;
}
p {
	font-family: 'Nice Tango', sans-serif;
}
.flipping {
	margin-top: -2em;
}
.connect-wallet-btn {
	position: absolute;
	top: 1rem;
	left: 1rem;
	font-family: 'Nice Tango', sans-serif;
	z-index: 100;
	color: #eee;
	border-radius: 2em;
	border: none;
	width: 12em;
	background-color: rgb(150, 72, 228);
	cursor: pointer;
	transition: background-color 0.3s ease;
	transition: box-shadow .25s linear,-webkit-transform .35s ease-in-out;
    transition: transform .35s ease-in-out,box-shadow .25s linear;
    transition: transform .35s ease-in-out,box-shadow .25s linear,-webkit-transform .35s ease-in-out;
  }
  .connect-wallet-btn:hover {
	background-color: rgba(150, 72, 228, 0.692);
	-webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
  .connect-wallet-btn:hover{
	box-shadow: 0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08)
  }
.connect-wallet-info {
	position: absolute;
	top: 0;
	left: 1rem;
	z-index: 100;
}
.txt-info {
	font-family: 'Nice Tango', sans-serif;
	font-size: 1.5em;
	margin-top: 2em;
}
.bet-section {
    margin-top:6em;

}

.bet-section p {
    margin-bottom: 2em;
    font-size: 1.6em;
    color: #333;
}

.button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.3em;
	
}

.button-container button {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    color: #000;
    background-color: #fedf57;
    border: none;
    border-radius: 4px;
	font-family: 'Nice Tango', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease;
	transition: box-shadow .25s linear,-webkit-transform .35s ease-in-out;
    transition: transform .35s ease-in-out,box-shadow .25s linear;
    transition: transform .35s ease-in-out,box-shadow .25s linear,-webkit-transform .35s ease-in-out
}
.selected {
    border: 3px solid #000!important
}
.selectedd {
    border: 3px solid #000!important
}
.button-container button:hover {
    background-color: #fedf5788;
	-webkit-transform: scale(1.05);
    transform: scale(1.05)
}
.button-container button:hover {
	box-shadow: 0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08)
}
.button-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
.btn-play-txt {
	font-family: 'Nice Tango', sans-serif;
	font-size: 1.5em;
	margin-top: 5em;
}
.icon-socials {
    display: inline-block;
    position: relative;
    overflow: hidden;
    color: #0088cc;
    width: 50px;
    height: 50px;
	cursor: pointer;
	margin: 10px;
}
@keyframes melt {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5) rotate(180deg);
        opacity: 0.5;
    }
    100% {
        transform: scale(0.1) rotate(360deg);
        opacity: 0;
    }
  }
  
  .hover-effect {
    animation: melt 0.5s ease-in-out forwards;
  }
  .icon-socials:hover {
    transform: scale(1.3); 
    transition: color 0.3s, transform 0.3s; 
  }
.socials-txt {
	font-family: 'Nice Tango', sans-serif;
	font-size: 1.5em;
	margin-top: 4em;
}
.footer {
    font-family: 'Nice Tango', sans-serif;
    font-size: 1em;
    margin-top: 6.5em;
    position: relative;
    display: inline-block; 
    overflow: hidden; 
}

.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
    animation: shine 5s infinite;
}

@keyframes shine {
    0% {
        left: -100%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: 100%;
    }
}
.coin-gif {
  width: 12em;
  height: 12em;
}
@media (max-width: 600px) {
	.connect-wallet-info {
		font-size: 12px;
	}
  .coin-gif {
    width: 10em;
    height: 10em;
  }
}