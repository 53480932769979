@import url('https://fonts.cdnfonts.com/css/nice-tango');

body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: aliceblue;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
}
.flip-btn {
	font-family: 'Nice Tango', sans-serif;
	font-size: 4em;
	align-items: center;
	text-align: center;
}
